<!--
 * @Description: 
 * @Author: huacong
 * @Date: 2021-10-30 18:12:19
 * @LastEditTime: 2021-12-02 09:59:11
 * @LastEditors: huacong
-->
<template>
  <div>
    <div class="appContent">
      <!-- <div class="title font-blue">建搜搜推出APP啦</div>
      <div class="title2 font-blue">
        随时随地 办资质, 搜企业, 搜人才, 了解最新资讯！
      </div>
      <div style="height:100px"></div> -->
      <img :class="bgClass" :src="downBg" alt="" srcset="" />
      <!-- pc -->
      <div class="pcbtn" v-if="pcShow">
        <el-button type="primary" @click="appdown">下载安卓应用</el-button>
        <el-button type="primary">ios应用开发中...</el-button>
      </div>
      <!-- 安卓 -->
      <div class="anzhuo" v-if="anShow">
        <el-button type="primary" @click="appdown">下载安卓应用</el-button>
      </div>
      <!-- ios -->
      <div class="iosbox font-blue" v-if="iosShow">
        <span>非常抱歉，苹果系统正在开发中,敬请期待...</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logo: require("@/assets/img/logo.png"),
      downBg: require("@/assets/img/appDownload.png"),
      pcShow: false,
      iosShow: false,
      anShow: false,
      version: {},
      bgClass: "",
      androidUrl: "",
      IosUrl: "",
    };
  },
  methods: {
    downApp() {
      let u = navigator.userAgent;
      // let app = navigator.appVersion;
      this.version.trident = u.indexOf("Trident") > -1; //IE内核;
      this.version.trident = u.indexOf("Trident") > -1; //IE内核
      this.version.presto = u.indexOf("Presto") > -1; //opera内核
      this.version.webKit = u.indexOf("AppleWebKit") > -1; //苹果、谷歌内核
      this.version.gecko = u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1; //火狐内核
      this.version.mobile =
        !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/); //是否为移动终端
      this.version.ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      this.version.android =
        u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端或者uc浏览器
      this.version.iPhone = u.indexOf("iPhone") > -1 || u.indexOf("Mac") > -1; //是否为iPhone或者QQHD浏览器
      this.version.iPad = u.indexOf("iPad") > -1; //是否iPad
      this.version.webApp = u.indexOf("Safari") == -1; //是否web应该程序，没有头部与底部
      console.log(u);
      if (this.version.mobile || this.version.ios) {
        console.log("移动端");
        this.bgClass = "downbg-app";
      } else {
        console.log("PC端");
        this.bgClass = "downbg-pc";
        this.pcShow = true;
      }
      if (this.version.ios || this.version.iPhone || this.version.iPad) {
        // window.location = "这里放上你ios安装包地址";
        this.iosShow = true;
      } else if (this.version.android) {
        // window.location = "这里放上你安卓安装包地址";
        this.anShow = true;
      }
    },
    appdown() {
      window.location = this.androidUrl;
    },
    getDownUrl() {
      this.$http
        .post("index/login/getlatestversion", { type: "Android" })
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.androidUrl = res.data.resultData.version_url;
          }
        });
      // this.$http
      //   .post("index/login/getlatestversion", { type: "Ios" })
      //   .then((res) => {
      //     if (res.data.resultCode === 1) {
      //       this.IosUrl = res.data.resultData.version_url;
      //     }
      //   });
    },
  },
  mounted() {
    this.downApp();
    this.getDownUrl();
  },
};
</script>
<style scoped>
.nosebox {
  background-color: #131d28;
  padding: 10px;
  text-align: center;
}
section .logo {
  margin-top: 5px;
}
.nosebox section {
  height: 80px;
  display: flex;
  align-items: center;
}
.loginBtn {
  float: right;
  padding: 12px 20px;
  background: #2573f1;
  color: #fff;
  border-radius: 50px;
  margin-left: auto;
}
.loginBtn span {
  cursor: pointer;
}
.note {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
}
.appContent {
  /* padding: 40px 20px; */
  text-align: center;
}
.downbg-pc {
  width: 460px;
}
.downbg-app {
  width: 100%;
}
.appContent .title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}
.appContent .title2 {
  font-size: 16px;
  font-weight: bold;
}
.anzhuo {
  width: 92%;
  margin-left: 4%;
}
.anzhuo button {
  width: 100%;
}
.iosbox {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
}
</style>
